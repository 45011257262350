import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";

import Layout from "../components/layout";
import Container from "../components/container";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
  pageContext,
}) {
  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  return (
    <Layout>
      <section className="page blog-template">
        <div className="section-center">
          <Container className="page">
            <div className="blog-post">
              <h1 style={{ textAlign: "left", fontSize: "2.5rem" }}>
                {frontmatter.title}
              </h1>
              <span>Date de dernière mise à jour: {frontmatter.date}</span>
              <Content
                className="blog-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </Container>
        </div>
      </section>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "DD MMMM YYYY", locale: "fr")
        slug
        title
      }
    }
  }
`;

const Content = styled.div`
  ol {
    font-weight: bold;
  }

  a {
    color: var(--clr-primary-8);
    :hover {
      text-decoration: underline;
      color: var(--clr-primary-6);
    }
  }
`;
